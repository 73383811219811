import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 20px 10px;
    padding-top: 90px;
`;
export const Title = styled.div`
    display: flex;
    color: black;
    align-items: center;
    color: #FC0000;
    

    & h{
        font-family: 'Josefin Sans', sans-serif;
    }
`;
export const Created = styled.div`
    display: flex;
    flex-direction: column;
    color: grey;
    font-size: 15px;
    gap: 0px;

    & p{
        font-family: 'Josefin Sans', sans-serif;
    }
`;
export const Author = styled.div`
    height: 30px;
`;
export const Release = styled.div`
    height: 30px;
`;
export const News = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-align: justify;
    
    & p{
        font-family: 'Josefin Sans', sans-serif;
    }

    @media (min-width: 360px) {
        font-size: 150%; 
    } 
    @media (min-width: 768px) {
        font-size: 150%; 
    }
    @media (min-width: 992px) {
        font-size: 160%; 
    }   
`;
export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 275px) {
        width: 100%;
    }
    @media (min-width: 360px) {
        width: 100%;
    }
    @media (min-width: 576px) {
        width: 100%; 
    }
    @media (min-width: 768px) {
        width: 50%; 
    }
    @media (min-width: 992px) {
        width: 30vw;
        height: 30vw; 
    }
    
`
export const Imuge = styled.img`
    @media (min-width: 275px) {
        width: 100%;
    }
    @media (min-width: 360px) {
        width: 100%;
    }
    @media (min-width: 576px) {
        width: 100%; 
    }
    @media (min-width: 768px) {
        width: 100%; 
    }
    @media (min-width: 992px) {
        width: 100%;  
    }
`