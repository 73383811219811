import { React, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { Container,
          Title,
          Created,
          Author,
          Release,
          News,
          ImageContainer,
          Imuge
        } from '../style/ArticlePageStyle.js';
import axios from 'axios';
import draftToHtml from 'draftjs-to-html';

const ArticlePage = () => {
    const location = useLocation();
    const id = location.pathname.split("/")[2];

    const [article, setArticle] = useState({});
    const [raw,setRaw] = useState({});
    const [consen, setConsen] = useState("");

    
    useEffect(() => {
      const getArticleById = async ()=> {
          const res = await axios.get('103.193.178.29:5000/api/articles/find/' + id)
          setArticle(res.data);
          setRaw(JSON.parse(res.data.content?.[0]));
        };
        getArticleById()
      }, [id]);
    
    useEffect (()=>{
      setConsen(draftToHtml(raw));
    })

    function ed () { 
        return { __html: consen }
    };
  
  return (
    <Container>
      <Title>
        <h3>{article.title}</h3>
      </Title>
      <Created>
        <Author>
          <p>Ditulis oleh : {article.author}</p>
        </Author>      
        <Release>
          <p>Posted : {new Date(article.createdAt).toDateString()}</p>
        </Release>
      </Created>
      <ImageContainer>
          <Imuge src= {article.image} />
      </ImageContainer>
      <News>      
        <div dangerouslySetInnerHTML={ ed() } />
      </News>
    </Container>
  )
}

export default ArticlePage