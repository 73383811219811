import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';

import { Container,
         Title,
         Created,
         Author,
         Release,
         News,
         Read,
         Fill,
         StyledLink,
} from '../style/ArticleStyle.js';

import { createGlobalStyle } from 'styled-components';

const Article = ({item}) => {
    const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Josefin Sans', sans-serif;
    }`

    const raw = JSON.parse(item.content);

    const [conten, setConten] = useState('');

    useEffect (
        ()=>{
            setConten(draftToHtml(raw));
    });

    function ed () { 
        return { __html: conten }
    };

    return (
    <>
        <Container>
            <GlobalStyle />
            <Title> 
                <h3>{item.title}</h3> 
            </Title>
            <Created>
                <Author>
                    <p>Ditulis oleh : {item.author}</p>
                </Author>
                <Release>
                    <p>Posted : {item.date} - {item.month} - {item.year}</p>
                </Release>
            </Created>
            <News>
                <Fill>
                    <div dangerouslySetInnerHTML={ ed() } />
                </Fill>
                <Read>
                    <StyledLink to={`/ArticlePage/${item._id}`}>Baca selengkapnya</StyledLink>
                </Read>
            </News>
            <Outlet />
        </Container>
        <hr />
    </>
  );
};

export default Article;