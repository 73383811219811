import {React, useState, useEffect} from 'react';
import { Container } from '../style/ArticleListStyle.js';
import Article from './Article.js';
import axios from 'axios';

const ArticleList = () => {
  const [article, setArticle] = useState([]);
  
  const getArticle = async ()=> {
      try{
        const res = await axios.get('103.193.178.29:5000/api/articles/')
        return res;
      }catch(err){
        console.log(err);
      }
    };

  useEffect(()=>{
    getArticle()
      .then((res)=> setArticle(res.data.sort((a,b)=>b.createdAt.localeCompare(a.createdAt))))
      .catch((err)=>{
        console.log(err);
      });
},[]) //-> dalam kurung siku adalah "depency", yg berguna mengaktifkan useEffect jika ada perubahan 
// di "depency"

  return (
    <Container>
      { article.map((item) => (
        <Article item={item} key={item._id}/>
      ))}
    </Container>
    );
};

export default ArticleList;