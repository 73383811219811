import React from 'react'
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Title, Content, Subtitle, ImageContainer, Contain } from '../style/TermAndCondPageStyle'
import { createGlobalStyle } from 'styled-components';
import Tabrakan from '../Image/Tabrakan.svg';
import Banjir from '../Image/Banjir.svg';


function TermAndCondPage() {
    
    const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Josefin Sans', sans-serif;
    }`
  
    return (
    <Contain>
        <GlobalStyle />
        <Container>
            <Row>
                <Col>
                    <Title>SYARAT & KETENTUAN</Title>
                    <Content>
                        Mohon dibaca dengan teliti dan seksama syarat dan ketentuan berikut sebelum menggunakan layanan dari GoCheckCar. 
                        Dengan menggunakan layanan kami, berarti anda kami anggap telah membaca, memahami, menerima dan menyetujui 
                        semua Syarat dan Ketentuan yang berlaku di bawah ini. Apabila anda tidak berkenan terhadap syarat dan ketentuan
                        dibawah ini baik sebagian maupun seluruhnya, kami perkenankan anda untuk tidak memakai layannan kami,
                    </Content>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Subtitle>Jasa Inspeksi Mobil Bekas</Subtitle>
                    <Content>
                        1. Pengecekan kendaraan kami lakukan seaktual mungkin dan sejujur jujurnya sesuai dengan kondisi kendaraan yang diperiksa tanpa adanya pengaruh dari eksternal (pemilik atau penjual kendaraan).
                        <br />
                        2. Laporan inspeksi adalah hasil pengecekan pada waktu dan tempat yang telah disepakati saat pemesanan atau order dilakukan. Kami tidak bertanggung jawab atas perubahan yang terjadi pada kondisi kendaraan setelah inspeksi dilakukan. 
                        <br />
                        3. Untuk mencegah perubahan kondisi kendaraan kami sarankan untuk mengecek tanggal laporan pengecekan masih dalam rentan waktu berdekatan dengan anda melakukan transaksi calon kendaraan anda.
                        <br />
                        4. Fokus dari jasa pengecekan GocheckCar adalah untuk mendeteksi kendaraan dari bekas tabrak, bekas banjir dan kondisi komponen dari indikasi kerusakan baik mesin transmisi kaki kaki, interior dan eksterior kendaraan.
                        <br />
                        5. Hasil pemeriksaan item / komponenen tercantum pada laporan hasil inspeksi. Kami tidak akan melakukan pemeriksaan komponen pada item laporan inspeksi jika komponen tersebut tidak tersedia pada kendaraan. Dan sebaliknya kami akan melakukan pengecekan pada komponen yang kami anggap perlu meskipun tidak tercantum pada check sheet / lembar pengecekan.
                        <br />
                        6. Ada kemungkinan kami tidak bisa mendeteksi kerusakan atau abnormalitas yang bersifat internal (Harus membongkar mesin atau komponenen kedaraan) karena inspeksi tidak dilakukan dengan membongkar komponen atau mesin kendaraan . Kami hanya dapat mendeteksi kerusakan yang terlihat pada saat inspeksi.
                        <br />
                        7. Kami tidak dapat mendeteksi keaslian nominal odometer kendaraan yang kami inspeksi. Keaslian odometer kami lihat dari hasil inspeksi fisik dan visual kendaraan.
                        <br />
                        8. Kerusakan yang terlihat secara visual akan kami laporkan sesuai hasil inspeksi. Namun untuk kerusakan bersifat minor mungkin tidak dilaporkan atau tergantung pada penilaian teknisi kami di lapangan.
                        <br />
                        9. Adapun beberapa item pengecekan tidak selalu dapat dilakukan karena beberapa faktor seperti dokumen kendaraan, scan OBD. Untuk item yang tidak dapat dilakukan pengecekan akan kami cantumkan dilaporan hasil inspeksi.
                        <br />
                        10. Hasil pengecekan pada laporan diantaranya skor pada laporan inspeksi, adalah penilaian kami terhadap kondisi kendaraan pada saat inspeksi dilakukan berdasarkan standar internal yang kami tetapkan sendiri atau berdasarkan pengalaman kami dalam melakukan inspeksi kendaraan.
                        <br />
                        11. Estimasi biaya perbaikan bersifat perkiraan, adapun estimasi ini dibuat mendekati dengan biaya aktual. Adapun selisih estimasi biaya bergantung pada lokasi / daerah dilakukan perbaikan.
                        <br />
                        12. Pahami istilah istilah yang kami gunakan pada metode pelaporan hasil inspeksi kami disini, untuk menghindari kesalahan pemahaman saat membaca laporan inspeksi dari kami. Pastikan anda mengerti sebelum anda melakukan pemesanan jasa kami.
                        <br />
                        13. Agar proses inspeksi berjalan lancar, pastikan anda (pemohon jasa inspeksi) memastikan ketersediaan pihak pemilik kendaraan untuk dilakukan inspeksi oleh tim kami. Apabila proses inspeksi tidak berjalan optimal karena pemilik kendaraan tidak bersedia maka hal ini sepenuhnya tanggaung jawab dari pemohon jasa inspeksi. 
                        <br />
                        14. Kemungkinan kami tidak dapat mengeluarkan nilai atau skor hasil inspeksi apabila proses inspeksi tidak berjalan optimal dikarenakan pemilik kendaraan tidak bersedia atau membatasi proses jalananya inspeksi yang dialakukan oleh tim kami. 
                        <br />
                        15. Nilai atau skor hasil inpeksi, pernyataan bebas dari indikasi tabrakan dan/atau terendam banjir, hanya bisa kami keluarkan dari data hasil pemerikasaan yang lengkap dan optimal.
                        <br />
                        16. Hasil laporan akan kami berikan minimal 1jam setelah proses inspeksi kendaraan selesai dialkukan atau maksimal 1x24 jam. 
                        <br />
                        17. Data hasil laporan inspeksi akan kami berikan kepada peminta inspeksi namun data tersebut merupakan milik kami selaku jasa inspeksi dan data tersebut akan kami simpan di data base kami. Adapun data tesebut adalah hak dan berada di bawah wewenang kami dan kami berhak untuk menggunakan data tersebut untuk kepentingan kami.
                        <br />
                        18. Penjadwalan ulang atau pembatalan inspeksi hanya bisa dilakukan apabila, ada info dari pemohon jasa inspeksi maksimal 30 menit sebelum jadwal pengecekan, kondisi cuaca atau linkungan yang tidak mendukung dilakukan inspeksi dan/atau kondisi lain yang membuat tidak mungkin dilakukannya pengecekan.
                        <br />
                        19. Pengembalian dana dari pembatalan akibat kondisi diatas akan dikenakan pemotongan biaya 50% administrasi. Selain alasan diatas tersebut dana tidak dapat dikembalikan.
                        <br />
                        20. Pembayaran biaya inspeksi wajib diterima maksimal 3 (tiga) jam setelah jadwal inspeksi dikonfirmasi oleh team penjadwalan. Jika belum diterima, kami berhak untuk mengalihkan jadwal ke customer lain.
                        <br />
                        21. Biaya inspeksi yang sudah dibayar akan hangus apabila pembatalan inspeksi dilakukan secara mendadak dan/atau sepihak.
                        <br />
                        22. Biaya inspeksi yang sudah dibayar dan belum digunakan akan disimpan sebagai deposit. Deposit akan hangus dalam kurun waktu 30 (tiga puluh) hari kalender setelah tanggal pembayaran jika tidak digunakan.
                        <br />
                        23. Kami bertindak hanya sebagai jasa layanan inspeksi dan kami tidak akan memberikan rekomendasi kepada anda apakah anda harus membeli kendaraan yang sudah diinspeksi atau tidak. Keputusan untuk membeli atau tidak membeli sepenuhnya adalah hak pembeli / peminta inspeksi. Kami tidak bertanggung jawab atas sah atau tidaknya hak penjual kendaraan untuk menjual kendaraannya kepada anda.
                        <br />
                        24. Go check car tidak bertanggung jawab atas kerugian apapun yang timbul baik secara langsung maupun tidak langsung yang berhubungan dengan keputusan pembelian kendaraan yang kami inspeksi. Jasa kami hanya bersifat membantu dan kami selalu menyarankan untuk melihat kendaraan terlebih dahulu sebelum membeli untuk memastikan kondisi kendaraan sesuai dengan harapan Anda.
                    </Content>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Subtitle>Definisi Tabrakan</Subtitle>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col>
                    <Image src={Tabrakan} fluid/>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Content>
                        Tabrakan kami tetapkan sebagai benturan yang mengakibatkan kerusakan pada struktur mobil ditandai dengan warna merah pada gambar. Hal tersebut akan mengurangi keamanan penumpang dan akan mengganggu fungsi kendaraan dan tidak bisa diperbaiki seperti semula.
                        Benturan yang terjadi pada kolong mobil tidak termasuk dalam indikasi tabrakan.
                    </Content>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Subtitle>Definisi Terendam Banjir</Subtitle>
                    <Content>
                        Kendaraan kami definisikan terindikasi terendam banjir jika air masuk dan merendam kabin interior dengan tinggi minimal di atas tempat duduk jok mobil, seperti tergambar pada diagram di bawah.
                    </Content>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Image src={Banjir} fluid />
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Content>
                        Jika mobil menerjang banjir atau air masuk ke dalam kabin tapi ketinggian air lebih rendah dari batas yang ditentukan seperti pada diagram di atas, maka mobil tersebut masih bisa kami definisikan bebas dari indikasi terendam banjir.
                    </Content>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Content>
                        Syarat dan Ketentuan di atas dapat berubah dan/atau diperbaharui sewaktu-waktu tanpa pemberitahuan sebelumnya. Kami menyarankan agar Anda membaca dan memeriksa halaman ini dari waktu ke waktu untuk mengetahui perubahan apapun.
                    </Content>
                </Col>
            </Row>
        </Container>
    </Contain>
  )
}

export default TermAndCondPage
//kgkjgkgkjgkjh