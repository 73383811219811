import React from 'react';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import HomePage from './Page/HomePage';
import ArticleListPage from './Page/ArticleListPage';
import ArticlePage from './Page/ArticlePage';
import TermAndCondPage from './Page/TermAndCondPage';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.js';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element= {<HomePage />} />
        <Route path='/ArticleListPage' element={<ArticleListPage />} />
        <Route path='/ArticlePage/:id' element={<ArticlePage />} />
        <Route path='/TermAndCondPage' element={<TermAndCondPage />}/>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

// {/* <Route path='/LoginPage' element={user ? redirect (<HomePage/>):(<LoginPage/>)} /> */}
