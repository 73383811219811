import styled from 'styled-components'

export const Contain = styled.div`
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding-top : 8vw;
    

    @media (min-width: 275px){
        padding-top : 10vw;
    };
    @media (min-width: 360px){
        padding-top : 20vw;
    };
    @media (min-width: 540px){
        padding-top : 13vw;
    };
    @media (min-width: 912px){
        padding-top : 8vw;
    };
    
`
export const Text = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1px;
    word-wrap: break-word;
    overflow-wrap: auto;
    text-align: justify;

    & p{
        font-family: 'Josefin Sans', sans-serif;
    }
    @media (min-width: 275px){
        width: 90vw;
        padding-top: 23vw;
        padding-bottom: 5vw;
    };
    @media (min-width: 275px) and (min-height:800px){
        width: 240vw;
        padding-top: 23vw;
        padding-bottom: 5vw;
    };
    @media (min-width: 360px){
        width: 95vw;
        padding-top: 23vw;
        padding-bottom: 5vw;
    };
    @media (min-width: 540px){
        width: 63vw;
        padding-top: 10vw;
        padding-bottom: 5vw;
    };
    @media (min-width: 540px) and (min-height: 540px){
        width: 90vw;
        padding-top: 13vw;
        padding-bottom: 5vw;
    };
    @media (min-width: 640px) and (min-height: 360px){
        width : 90vw;
        height : auto;
        margin-left : 3vw;
    };
    @media (min-width: 740px){
        width : 80vw;
        height : auto;
        margin-left : 8vw;
    };
    @media (min-width: 768px){
        width: 90vw;
        padding-top: 10vw;
        padding-bottom: 5vw;
        margin-left : 4vw;
    };
    @media (min-width: 820px){
        width: 90vw;
        padding-top: 10vw;
        padding-bottom: 5vw;
        margin-left : 2vw;
    };
    @media (min-width: 912px){
        width: 90vw;
        padding-top: 7vw;
        padding-bottom: 5vw;
        margin-left : 2vw;
    };
`
export const Title = styled.h1`
    @media (min-width: 275px){
        font-size: 10vw;
        font-weight: bold;
    };
    @media (min-width: 360px){
        font-size : 6vw;
    };
    @media (min-width: 540px){
        font-size : 3vw;
    };
`
export const Content = styled.div`
    @media (min-width: 275px){
        font-size: 30px;
        font-weight: bold;
    };
    @media (min-width: 360px){
        font-size: 18px;
        font-weight: bold;
    };
    @media (min-width: 540px){
        font-size: 12px;
        font-weight: bold;
    };
    @media (min-width: 912px){
        font-size: 20px;
        font-weight: bold;
    };
`
export const Subtitle = styled.h5`
    text-decoration: underline;
    text-weight: bold;
    @media (min-width: 275px){
        font-size: 10vw;
        font-weight: bold;
    };
    @media (min-width: 360px){
        font-size: 8vw;
        font-weight: bold;
    };
    @media (min-width: 540px){
        font-size : 3vw;
        font-weight: bold;
    };
`
export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 275px){
        width: 240vw;
        padding-top: 23vw;
        padding-bottom: 5vw;
    };

    @media (min-width: 275px) and (min-height:800px){
        width: 200vw;
        padding-top: 23vw;
        padding-bottom: 5vw;
    };

    @media (min-width: 360px){
        width : 50vw;
        height : auto;
        margin-left : 15vw;
    };
    @media (min-width: 540px){
        width : 50vw;
        height : auto;
        margin-left : 10vw;
    };
    @media (min-width: 640px) and (min-height: 360px){
        width : 90vw;
        height : auto;
        margin-left : 0vw;
        margin-top : 0;
        margin-bottom : 0;
        padding-top : 0;
        padding-bottom : 0;
        padding-left : 0;
    };
    @media (min-width: 768px){
        width : 70vw;
        height : auto;
        margin-left : 10vw;
        margin-top : 0;
        margin-bottom : 0;
        padding-top : 0;
        padding-bottom : 0;
        padding-left : 0;
    };
    @media (min-width: 912px){
        display : flex;
        align-item : center;
        width : 80vw;
        height : auto;
        margin-top : 0;
        margin-bottom : 0;
        margin-left : 0;
        padding-top : 0;
        padding-bottom : 0;
        padding-left : 0;

    };
`
export const Image = styled.img`
    @media (min-width: 275px) and (min-height:800px){
        width: 80%;
    };
    @media (min-width: 360px) {
        width: 100%; 
    };
    @media (min-width: 540px) {
        width: 70%; 
    };
    @media (min-width: 640px) and (min-height: 360px){
        width : 50%;
    };
    @media (min-width: 768px) {
        width: 30%; 
    };
    @media (min-width: 912px) {
        width: 50%; 
    };
`