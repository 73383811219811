import { React } from 'react';



// Gambar untuk Headline
import HeadLine from '../Image/Logo Web - Freepik.svg';

// Gambar untuk Alur Pemesanan
import DownPayment from '../Image/Image Pemesanan/Flow 1 - manypixels.svg';
import Confirmation from '../Image/Image Pemesanan/Flow 2 - undraw.svg';
import Checking from '../Image/Image Pemesanan/Flow 3 - undraw.svg';
import Report from '../Image/Image Pemesanan/Flow 4 - manypixels.svg';

// Gambar untuk Bagian Pemeriksaan
import Eksterior from '../Image/Area Cek/Car.svg';
import Interior from '../Image/Area Cek/Car Seat.svg';
import Rangka from '../Image/Area Cek/Car Frame.svg';
import Machine from '../Image/Area Cek/Car Machine.svg';
import Document from '../Image/Area Cek/Car Document.svg';

// Gambar untuk Biaya
import smallCar from '../Image/Biaya/smallCar.svg';
import mediumCar from '../Image/Biaya/mediumCar.svg';
import largeCar from '../Image/Biaya/largeCar.svg';

// Styled Component
import { Container, Basket, Tuxt, ImageContainer, Image, Tixt, Judul, Isi, Tombol, Flow,
    Step, Model, ImageFlow, Caption, FlowCheck, Check, Upper, Lower, Satu, Dua, Tiga, 
    Empat, Lima, GambarContainer, GambarSatu, GambarDua, GambarTiga, GambarLima, Paint, 
    CaptionUpper, CaptionLower, FlowBiaya, StepBiaya, JudulBiaya, CaptionBiaya, 
    ModelMobil, ImageMobil, Pesan,  
    } from '../style/HomePageStyle.js'

// Import untuk Font Tulisan
import { createGlobalStyle } from 'styled-components';

//Pop up Syarat dan Kondisi


function HomePage() {
    const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Josefin Sans', sans-serif;
    }`

  return (
    <Container>
        <GlobalStyle />
       

{/* KOLOM HEADLINE */}
        <Basket>
            <Tuxt>
                <ImageContainer>
                    <Image src={HeadLine} />
                </ImageContainer>
            </Tuxt>
            <Tixt>
                <Judul>
                    <h1>JASA INSPEKSI MOBIL</h1>
                </Judul>
                
                <Isi>
                    <p>KAMI MENJAWAB KEBUTUHAN ANDA UNTUK MEMASTIKAN MOBIL 
                    YANG ANDA AKAN BELI, BERKUALITAS</p>
                </Isi>
                <Tombol onClick={(e)=>{
                    e.preventDefault();
                    window.location.href = 'https://www.instagram.com/go.checkcar/'
                }}>
                    BOOKING
                </Tombol>
            </Tixt>
        </Basket>

{/* KOLOM FLOW PENGGUNAAN JASA PENGECEKAN */}
        <Flow>
            <Judul>
                <h1>Alur Pemesanan</h1>
            </Judul>
            <Step>
                <Model>
                    <ImageFlow src={DownPayment} />
                    <Caption>
                        01. 
                        <br />
                        Klik Booking, untuk melakukan pemesanan, 
                        pembayaran DP (sebesar 10% dari total biaya) 
                        dengan CS Kami
                    </Caption>
                </Model>
                <Model>
                    <ImageFlow src={Confirmation} />
                    <Caption>
                        02. 
                        <br />
                        Informasikan Detail Jenis Kendaraan, Jenis 
                        Inspeksi yang akan Dilakukan Kepada CS Kami
                    </Caption>
                </Model>
                <Model>
                    <ImageFlow src={Checking} />
                    <Caption>
                        03. 
                        <br />
                        Tim Kami Segera Melakukan Pengecekan Ke Lokasi 
                        Sesuai Jadwal yang Telah Dibuat dan Disepakati
                    </Caption>
                </Model>
                <Model>
                    <ImageFlow src={Report} />
                    <Caption>
                        04. 
                        <br />
                        Laporan Hasil Inspeksi akan Dikirim ke Anda, 
                        maksimal 6 jam Setelah Inspeksi Dilakukan 
                    </Caption>
                </Model>
            </Step>
        </Flow>

{/* KOLOM PENGECEKAN YANG DILAKUKAN */}
        <FlowCheck>
            <Judul>
                <h1>Area Pemeriksaan</h1>
            </Judul>
            <Check>
                <Upper>
                    <Satu>
                        <GambarContainer>
                            <GambarSatu src={Eksterior} />
                        </GambarContainer>
                        
                        <CaptionUpper>
                            01. EKSTERIOR
                            <br />
                            Pada Area ini, Kami Melakukan Pengecekan pada Visual Luar 
                            Mobil, seperti Kondisi Cat Mobil, dan Lainnya.
                        </CaptionUpper>
                    </Satu>
                    <Dua>
                        <GambarContainer>
                            <GambarDua src={Interior} />
                        </GambarContainer>
                        
                        <CaptionUpper>
                            02. INTERIOR
                            <br />
                            Pada Area ini, Kami Melakukan Pengecekan Bagian Dalam Mobil
                            Seperti Kondisi Jok Mobil dan Lainnya.
                        </CaptionUpper>
                    </Dua>
                    <Tiga>
                        <GambarContainer>
                            <GambarTiga src={Rangka} />
                        </GambarContainer>
                        
                        <CaptionUpper>
                            03. FRAME
                            <br />
                            Pada Area ini, Kami Melakukan Pengecekan Kondisi Suspensi
                            Mobil dan Lainnya.
                        </CaptionUpper>
                    </Tiga>
                </Upper>
                <Lower>
                    <Empat>
                        <GambarContainer>
                            <Paint src={Machine} />
                        </GambarContainer>
                        
                        <CaptionLower>
                            04. MESIN
                            <br />
                            Pada Area ini, Kami Melakukan Pengecekan Kondisi Mesin, 
                            Sistem Kelistrikan Mobil dan Lainnya. 
                        </CaptionLower>
                    </Empat>
                    <Lima>
                        <GambarContainer>
                            <GambarLima src={Document} />
                        </GambarContainer>
                        
                        <CaptionLower>
                            05. DOKUMEN
                            <br />
                            Pada Area ini, Kami Melakukan Pengecekan Kelengkapan Dokumen 
                            Mobil
                        </CaptionLower>
                    </Lima>
                </Lower>
            </Check>
        </FlowCheck>


{/* KOLOM DAFTAR BIAYA */}
        <FlowBiaya>
            <JudulBiaya>
                <h1>Biaya Pengecekan</h1>
                <CaptionBiaya>
                    *Paket Dasar Mencakup Pengecekan Eksterior, Interior, Rangka, Mesin dan Transmisi
                    <br />
                    **Paket Lengkap seperti Paket Dasar ditambah OBD Check dan Estimasi Biaya Perbaikan
                </CaptionBiaya>
            </JudulBiaya>
            <StepBiaya>
                <ModelMobil>
                    <ImageMobil src={smallCar} />
                    <CaptionBiaya>
                        PAKET DASAR* : Rp 275.000,-
                        <br />
                        PAKET LENGKAP** : Rp 325.000,-
                    </CaptionBiaya>
                    <Pesan onClick={(e)=>{
                        e.preventDefault();
                        window.location.href = 'https://www.instagram.com/go.checkcar/'}}
                        >
                        BOOKING
                    </Pesan>
                </ModelMobil>
                <ModelMobil>
                    <ImageMobil src={mediumCar} />
                    <CaptionBiaya>
                        PAKET DASAR* : Rp 325.000,-
                        <br />
                        PAKET LENGKAP** : Rp 375.000,-
                    </CaptionBiaya>
                    <Pesan onClick={(e)=>{
                        e.preventDefault();
                        window.location.href = 'https://www.instagram.com/go.checkcar/'}}
                        >
                        BOOKING
                    </Pesan>
                </ModelMobil>
                <ModelMobil>
                    <ImageMobil src={largeCar} />
                    <CaptionBiaya>
                        PAKET DASAR* : Rp 375.000,-
                        <br />
                        PAKET LENGKAP** : Rp 425.000,-
                    </CaptionBiaya>
                    <Pesan onClick={(e)=>{
                        e.preventDefault();
                        window.location.href = 'https://www.instagram.com/go.checkcar/'}}
                        >
                        BOOKING
                    </Pesan>
                </ModelMobil>
            </StepBiaya>
        </FlowBiaya>
    </Container>
  )
}

export default HomePage

