import React from 'react';

import InstagramIcon from '@mui/icons-material/Instagram';

import { Container, 
        Logo, 
        Desc, 
        SocialContainer, 
        SocialIcon,
      } from '../style/FooterStyle'

const Footer = () => {
  return (
    <Container>
            <Logo>CAR CHECKING</Logo>
            <Desc>
                <div>Stay Connected with Us</div>
            </Desc>
            <SocialContainer>
                <SocialIcon color='E95950' onClick={
                  (e)=>{
                    e.preventDefault();
                    window.location.href='https://www.instagram.com/go.checkcar/'
                  }}>
                    <InstagramIcon />
                </SocialIcon>
            </SocialContainer>
    </Container>

  )
}

export default Footer