import styled from "styled-components";
import {Link} from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 10px;

    @media (min-width: 275px){
        gap: 5px;
    };
    @media (min-width: 360px){
        gap: 10px;
    };
    @media (min-width: 576px){};
    @media (min-width: 768px){
        gap: 0px;
    };
`;
export const Title = styled.div`
    display: flex;
    color: black;
    align-items: center;
    color: #FC0000;
    
    & h{
        font-family: 'Josefin Sans', sans-serif;
    }
`;
export const Created = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
    color: grey;
    font-size: 15px;

    & p{
        font-family: 'Josefin Sans', sans-serif;
    }
`;
export const Author = styled.div`
    height: 20px;
`;
export const Release = styled.div`
    height: 20px;
`;
export const News = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    
    & p{
        font-family: 'Josefin Sans', sans-serif;
    }
`;
export const Fill = styled.p`
    max-width: 1500px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
`;
export const Read = styled.button`
    width: 170px;
    height: 35px;
    font-size: 15px;
    background-color: #FC0000;
    border-radius: 10px;
    color: white;

    @media (min-width: 275px){};
    @media (min-width: 360px){};
    @media (min-width: 576px){};
    @media (min-width: 756px){};
`;
export const StyledLink = styled(Link)`
    text-decoration: none;
    color: white;
`
export const DelButton = styled.button`
    width: 170px;
    height: 35px;
    font-size: 15px;
    background-color: red;
    border-radius: 10px;    
    color: white;
`
