import React from 'react'

import {    
    Container,
    Pocket,
    } from '../style/ArticleListPageStyle.js'

import ArticleList from '../Component/ArticleList.js';


const ArticleListPage = () => {
    return (
    <Container>
        <Pocket>
            <ArticleList />
        </Pocket>
    </Container>
  )
}

export default ArticleListPage;