import { React } from 'react';
import { Outlet } from 'react-router-dom';

import { 
  Container, 
  Wrapper, 
  Left,  
  Logo,
  StyleLink,
  StyledLink,
  Right, 
  Bar
} from '../style/NavbarStyle';


import { createGlobalStyle } from 'styled-components';

const Navbar = () => {
  const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Josefin Sans', sans-serif;
    }`

  return (
    <Container>
      <GlobalStyle />
      <Wrapper>
        <Left>
          <Logo>
            <StyleLink to ='/' >GO CHECK CAR</StyleLink>
          </Logo>
        </Left>
        <Right>
            <Bar onClick={(e)=>{
              e.preventDefault();
              window.location.href='https://www.instagram.com/go.checkcar/'
            }}>
              Booking
            </Bar>
          <Bar>
            <StyledLink to='/ArticleListPage'>Artikel</StyledLink>
          </Bar>
          <Bar>
            <StyledLink to='/TermAndCondPage'>Term</StyledLink>
          </Bar>
        </Right>
      </Wrapper>
      <Outlet />
    </Container>
  )
}

export default Navbar
